import React, { useMemo, useCallback } from 'react';
import { Grid, CardMedia } from '@mui/material';
import { PropTypes } from 'prop-types';
import { menuLinksStyle as classes } from './styleObjects/MenuLinks';
import { keyIsEnter } from '@helpers/keyPressEventHelper';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { CMSLink } from '@components/CMSLink';
import { NewTabIcon } from '@icon/NewTabIcon';
import { Text } from '@web-for-marketing/react-ui';

export function MenuLinks({ title, menuItems, type, image, closeMenu, isGrouped, previewState }) {
    const { prefixStringWithBaseUrl } = useStaticValues();

    const onKeyDown = useCallback((event) => {
        keyIsEnter(event) && closeMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const linkTypeStyle = {
        primary: classes.primaryTextLink,
        secondary: classes.secondaryTextLink,
        groupTitle: classes.primaryGroupTextLink,
        tertiary: classes.primaryBold,
    };

    const renderedItems = useMemo(() => {
        let renderedItem = null;

        switch (type) {
            case 'withLinks':
                renderedItem = menuItems.map(({ title, variant, href, isTargetToNewTab }, index) => {
                    return (
                        <Grid
                            item
                            key={`${title} ${index}`}
                            css={[
                                title ? classes.menuTitleSpacing : '',
                                isGrouped && variant === 'default' && classes.primarySpacing,
                            ]}
                        >
                            <CMSLink
                                href={href}
                                css={[
                                    classes.internalLinkContainer,
                                    classes.MenuLinksContainerSpacing,
                                    linkTypeStyle[variant] || classes.primaryTextLink,
                                ]}
                                underline='none'
                                onClick={closeMenu}
                                onKeyDown={onKeyDown}
                                target={isTargetToNewTab ? '_blank' : '_self'}
                                title={isTargetToNewTab ? 'This link may open in a new tab' : undefined}
                                type='link'
                                tabIndex={previewState ? -1 : 0}
                            >
                                <Text variant='body2' color='tertiary'>
                                    {title} {isTargetToNewTab ? <NewTabIcon aria-hidden='true' /> : null}
                                </Text>
                            </CMSLink>
                        </Grid>
                    );
                });
                break;
            case 'withDescriptionLinks':
                renderedItem = menuItems.map(({ title, description, href, isTargetToNewTab }, index) => {
                    return (
                        <Grid key={`${title} ${index}`} css={classes.menuTitleSpacing}>
                            <CMSLink
                                href={href}
                                css={[
                                    classes.internalLinkContainer,
                                    classes.MenuLinksAndDescriptionContainerSpacing,
                                    classes.accessibleDescriptionFocus,
                                ]}
                                underline='none'
                                onClick={closeMenu}
                                onKeyDown={onKeyDown}
                                target={isTargetToNewTab ? '_blank' : '_self'}
                                title={isTargetToNewTab ? 'This link may open in a new tab' : undefined}
                                type='link'
                                tabIndex={previewState ? -1 : 0}
                            >
                                <Text color='tertiary' variant='body1' css={classes.menuPageLinkText}>
                                    {title} {isTargetToNewTab ? <NewTabIcon aria-hidden='true' /> : null}
                                </Text>
                                <Text color='tertiary' variant='body2'>
                                    {description}
                                </Text>
                            </CMSLink>
                        </Grid>
                    );
                });
                break;
            case 'withLinksAndImage':
                renderedItem = (
                    <>
                        {menuItems.map(({ title, href, variant, isTargetToNewTab }, index) => {
                            return (
                                <Grid item key={`${title} ${index}`} css={classes.menuTitleSpacing}>
                                    <CMSLink
                                        href={href}
                                        css={[
                                            classes.internalLinkContainer,
                                            classes.MenuLinksAndImageContainerSpacing,
                                            linkTypeStyle[variant] || classes.primaryTextLink,
                                        ]}
                                        underline='none'
                                        onClick={closeMenu}
                                        onKeyDown={onKeyDown}
                                        target={isTargetToNewTab ? '_blank' : '_self'}
                                        title={isTargetToNewTab ? 'This link may open in a new tab' : undefined}
                                        type='link'
                                        tabIndex={previewState ? -1 : 0}
                                    >
                                        <Text color='tertiary' variant='body2'>
                                            {title} {isTargetToNewTab ? <NewTabIcon aria-hidden='true' /> : null}
                                        </Text>
                                    </CMSLink>
                                </Grid>
                            );
                        })}
                        {image ? (
                            <CardMedia
                                component='img'
                                src={prefixStringWithBaseUrl(image.imageUrl)}
                                alt={image.imageAlt}
                                css={classes.bottomMedia}
                            />
                        ) : null}
                    </>
                );
                break;
            default:
                break;
        }

        return renderedItem;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuItems]);

    return (
        <Grid container direction='column' css={{ paddingTop: `${title ? '0px' : '2.5rem'}` }}>
            {title ? (
                <Text variant='body2' color='tertiary' weight='bold' css={(!title && classes.noMenuTitle) || ''}>
                    {title}
                </Text>
            ) : null}
            {renderedItems}
        </Grid>
    );
}

MenuLinks.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    menuItems: PropTypes.arrayOf(PropTypes.object),
    image: PropTypes.shape({
        imageUrl: PropTypes.string,
        imageAlt: PropTypes.string,
    }),
    isGrouped: PropTypes.bool,
    closeMenu: PropTypes.func,
    previewState: PropTypes.bool,
};

MenuLinks.defaultProps = {
    menuItems: [],
    spacing: true,
    seeAllIndustries: false,
    isGrouped: false,
    previewState: false,
};
