import { v2Colors } from '@web-for-marketing/react-core';

export const menuExpansionStyles = {
    noSpacing: {
        margin: '0 !important',
    },
    expansionPanel: {
        boxShadow: 'none',
        height: '100%',
        margin: '0 0.3rem !important',
        position: 'static !important' as 'static',
        zIndex: 'unset !important' as 'unset',
        '&::before': {
            display: 'none',
        },
        '& > div:last-child': {
            left: '0px',
            position: 'absolute',
            zIndex: '-1',
            width: '100%',
            transform: 'translateY(-100%)',
            visibility: 'hidden',
            transition:
                'transform 350ms cubic-bezier(0, 0, 0.2, 1), visibility 350ms cubic-bezier(0, 0, 0.2, 1), height 350ms cubic-bezier(0, 0, 0.2, 1) !important',
            '@media (prefers-reduced-motion: reduce)': {
                transition: 'none !important',
            },
        },
    },
    showUnderline: {
        '&::after': {
            height: '2px !important',
        },
    },
    animateExpansionMenu: {
        '& > div:last-child': {
            visibility: 'visible !important' as 'visible',
            transform: 'translateY(0)!important',
        },
        '& > div:last-child > * > div': {
            opacity: '1',
        },
    },
    menuTitles: {
        padding: '0 1.2rem 3rem',
        margin: '0 0.3rem !important',
        alignItems: 'flex-end',
        height: '100%',
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '0 !important',
        zIndex: 2,
        '&:hover::after': {
            height: 2,
        },
        '& > div ': {
            transition: 'none',
        },
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: 0,
            width: '100%',
            transition: 'height 300ms ease',
            backgroundColor: v2Colors.core.innovation,
            transform: 'translateY(1px)',
            zIndex: 100,
        },
        '@media (max-width: 1039.9px)': {
            padding: '0 0.6rem 3rem',
        },
    },
    expansionTitleFocus: {
        backgroundColor: 'rgba(1, 23, 41, 0.05) !important',
        '&::after': {
            height: 2,
        },
    },
    menuContent: {
        zIndex: 1,
        width: '100%',
        backgroundColor: 'white',
        left: '0 !important',
        padding: 'unset',
        boxShadow: '0px 2px 2px rgba(1, 23, 41, 0.05) !important',
        maxHeight: 'calc(100vh - 10rem)',
        overflowY: 'auto',
        overflowX: 'hidden',
        '& > div': {
            opacity: '0.7',
            transition: 'opacity 450ms cubic-bezier(0, 0, 0.2, 1) !important',
            '@media (prefers-reduced-motion: reduce)': {
                transition: 'none !important',
            },
        },
    },
    topShadow: {
        position: 'absolute',
        width: '100%',
        display: 'block',
        top: '-2px',
        height: '1px',
        boxShadow: '0px 2px 2px rgba(1, 23, 41, 0.05) !important',
    },
    gridBorder: {
        paddingTop: 0,
        paddingBottom: 0,
        '& > *': {
            position: 'relative',
            borderRight: 'solid 1px #CFD5DC',
            paddingTop: '4rem',
            paddingBottom: '4rem',
        },
        '& > *:last-child': {
            borderRight: 'unset',
        },
    },
} as const;
