import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, CardMedia } from '@mui/material';
import { singleColumnWithImageMenuStyle } from './styleObjects/SingleColumnWithImageMenu';
import { keyIsEnter } from '@helpers/keyPressEventHelper';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { CMSLink } from '@components/CMSLink';
import { NewTabIcon } from '@icon/NewTabIcon';
import { Heading, Text } from '@web-for-marketing/react-ui';

export function SingleColumnWithImageMenu({ content, closeMenu, previewState }) {
    const { linkTextStyle, menuContainer, bottomMedia, contentMargin, descriptionStyle } =
        singleColumnWithImageMenuStyle;
    const { title, description, links, image } = content;
    const { prefixStringWithBaseUrl } = useStaticValues();

    return (
        <Grid container direction='column' css={menuContainer} alignItems='flex-start'>
            <Heading variant='h3' weight='regular' css={contentMargin}>
                {title}
            </Heading>
            <Text variant='body2' css={[contentMargin, descriptionStyle]}>
                {description}
            </Text>
            <CMSLink
                href={links[0].href}
                variant='body1'
                underline='none'
                title={links[0].isTargetToNewTab ? 'This link may open in a new tab' : undefined}
                css={linkTextStyle}
                onClick={closeMenu}
                onKeyDown={(event) => {
                    keyIsEnter(event) && closeMenu();
                }}
                type='link'
                target={links[0].isTargetToNewTab ? '_blank' : '_self'}
                tabIndex={previewState ? -1 : 0}
            >
                {links[0].title} {links[0].isTargetToNewTab ? <NewTabIcon aria-hidden='true' /> : null}
            </CMSLink>
            <CardMedia
                component='img'
                src={prefixStringWithBaseUrl(image.imageUrl)}
                alt={image.imageAlt}
                css={bottomMedia}
            />
        </Grid>
    );
}

SingleColumnWithImageMenu.propTypes = {
    content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])).isRequired,
    previewState: PropTypes.bool,
    closeMenu: PropTypes.func,
};

SingleColumnWithImageMenu.defaultProps = {
    content: {
        title: '',
        description: '',
        link: {},
        image: {},
    },
    previewState: false,
};
